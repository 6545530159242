<template>
  <div id="app">
    <FormCenter />
  </div>
</template>

<script>
import FormCenter from "./components/FormCenter.vue";

export default {
  name: "App",
  components: {
    FormCenter
  }
};
</script>

<style>
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "阿里巴巴普惠体 2.0 55 Regular";
  font-weight: 400;
  src: url("//at.alicdn.com/wf/webfont/IoekGff1B5Xm/Ln3LBmgYHrbm.woff2")
      format("woff2"),
    url("//at.alicdn.com/wf/webfont/IoekGff1B5Xm/7VPxxrCPubOG.woff")
      format("woff");
  font-display: swap;
}

/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "思源黑体 Light";
  font-weight: 300;
  src: url("//at.alicdn.com/wf/webfont/IoekGff1B5Xm/F3efGJTOfOau.woff2")
      format("woff2"),
    url("//at.alicdn.com/wf/webfont/IoekGff1B5Xm/eBFbiIrG0G1m.woff")
      format("woff");
  font-display: swap;
}

/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "思源宋体 Light";
  font-weight: 300;
  src: url("//at.alicdn.com/wf/webfont/IoekGff1B5Xm/Apd7XexgZ7Uq.woff2")
      format("woff2"),
    url("//at.alicdn.com/wf/webfont/IoekGff1B5Xm/bxyvg9dNB3Dj.woff")
      format("woff");
  font-display: swap;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "阿里巴巴普惠体 2.0 55 Regular", "思源黑体 Light",
    "思源宋体 Light";
}

ul li {
  list-style: none;
}
</style>
