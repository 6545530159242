<template>
  <div>
    <div class="header" :class="{'fixed-header': isHeaderFixed}">
      <div class="header_logo_wrapper">
        <img src="../assets/logo.png" />
        <div class="title">7600</div>
        <div class="menu_icon" @click="showMenu">
          <img v-if="!isMenuVisible" src="../assets/menu_icon.png" />
          <img v-else src="../assets/menu_close_icon.png" />
        </div>
        <div v-if="isMenuVisible" class="menu_navigation">
          <div class="menu_nav_con">
            <div class="sub_li" v-for="(item, index) in menuItems" :key="index">
              <span class="sub_title">{{ item.title }}</span>
              <div class="sub_menu">
                <span
                  v-for="(subItem, subIndex) in item.subItems"
                  :key="subIndex"
                  @click="hideMenu"
                >{{ subItem }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="navigation">
        <a
          v-for="(item, index) in menuItems"
          :key="index"
          @mouseover="showSubMenu(index)"
          @mouseleave="hideSubMenu"
          @click="activateMenu(index)"
          :class="{ 'active': activeMenuItem === index }"
        >
          <span>{{ item.title }}</span>
          <div class="activeLine">
            <div class="activeLineInner"></div>
          </div>
          <div v-if="item.subMenuVisible" class="sub-menu">
            <span
              v-for="(subItem, subIndex) in item.subItems"
              :key="subIndex"
              @click="activateSubMenu(index)"
            >{{ subItem }}</span>
          </div>
        </a>
      </div>

      <div class="header_opts">
        <a :href="mmsurl" class="btn_login">登录/注册</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderRoot",
  props: {},
  data() {
    return {
      isNavVisible: false,
      mmsurl: process.env.VUE_APP_MMS_BASE_URL,
      isHeaderFixed: false,
      menuItems: [
        {
          title: "模板中心",
          subItems: ["子菜单1-1", "子菜单1-2", "子菜单1-3", "子菜单1-4"],
          subMenuVisible: false
        },
        {
          title: "产品服务",
          subItems: ["子菜单2-1", "子菜单2-2", "子菜单2-3", "子菜单2-4"],
          subMenuVisible: false
        },
        {
          title: "解决方案",
          subItems: ["子菜单3-1", "子菜单3-2", "子菜单3-3", "子菜单3-4"],
          subMenuVisible: false
        },
        {
          title: "生态合作",
          subItems: ["子菜单4-1", "子菜单4-2", "子菜单4-3", "子菜单4-4"],
          subMenuVisible: false
        },
        {
          title: "Api对接",
          subItems: ["子菜单5-1", "子菜单5-2", "子菜单5-3", "子菜单5-4"],
          subMenuVisible: false
        }
      ],
      activeMenuItem: null,
      isMenuVisible: false
    };
  },
  mounted() {
    console.log("URL::" + process.env.VUE_APP_MMS_BASE_URL);
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    //菜单固定
    handleScroll() {
      this.isHeaderFixed = window.scrollY > 0;
    },
    //二级菜单浮层
    showSubMenu(index) {
      this.menuItems[index].subMenuVisible = true;
    },
    hideSubMenu() {
      this.menuItems.forEach(item => {
        item.subMenuVisible = false;
      });
    },
    activateMenu(index) {
      this.activeMenuItem = index;
    },
    activateSubMenu(index) {
      this.activeMenuItem = index;
      // 可以在这里添加其他点击二级菜单时需要执行的逻辑
    },
    gotomms() {
      window.location.href = "https://t.7600.cn/mms";
    },
    showMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    hideMenu() {
      this.isMenuVisible = false;
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 60px;
  padding: 0 40px;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;

  .header_logo_wrapper {
    display: flex;
    align-items: center;
    height: 42px;
    cursor: pointer;
    img {
      height: 42px;
    }
    .title {
      font-size: 24px;
      font-weight: 500;
      color: white;
    }
    .menu_icon {
      z-index: 9999;
      display: none !important;
      margin-left: 16px;
      border-radius: 4px;
      padding: 20px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .menu_navigation {
      width: 100%;
      position: absolute;
      z-index: 101;
      top: 0;
      left: 0;
      padding-top: 60x;
      border-radius: 0 0 2px 2px;
      background: none;
      .menu_nav_con {
        background: rgba(16, 16, 16, 0.9);
        backdrop-filter: blur(24px);
        padding: 24px 0 0 24px;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        .sub_li {
          color: #cccccc;
          margin-right: 40px;
          margin-bottom: 32px;
          .sub_title {
            display: flex;
            margin-bottom: 12px;
            margin-left: 12px;
          }
          .sub_menu {
            display: flex;
            flex-direction: column;
            span {
              padding: 8px 12px;

              &:hover {
                background: #38f;
                border-radius: 2px;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }

  .navigation {
    flex-grow: 1;
    margin-left: 20px;
    a {
      position: relative;
      display: inline-block;
      margin: 0 32px;
      line-height: 50px;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      font-size: 14px;
      color: #ffffff;
      .activeLine {
        width: 100%;
        position: absolute;
        bottom: 8px;
        left: 0;
        display: none;
        justify-content: center;
        .activeLineInner {
          width: 14px;
          height: 3px;
          border-radius: 6px;
          background: #ffffff;
        }
      }
      &.active {
        font-weight: 600;
        .activeLine {
          display: inline-flex;
        }
      }
      &:hover {
        cursor: pointer;
        .activeLine {
          display: inline-flex;
        }
      }
      .sub-menu {
        position: absolute;
        z-index: 101;
        padding: 10px 0;
        width: 160px;
        left: -24px;
        top: 0;
        padding-top: 55px;
        display: flex;
        flex-direction: column;
        border-radius: 0 0 2px 2px;
        background: none;
        span {
          background: rgba(11, 13, 18, 0.8);
          backdrop-filter: blur(24px);
          color: #cccccc;
          line-height: 40px;
          padding: 0 24px;
          &:hover {
            background: rgba(11, 13, 18, 0.5);
            color: #ffffff;
          }
        }
      }
    }
  }
  .header_opts {
    display: flex;
    align-items: center;
    .btn_login {
      height: 36px;
      border-radius: 4px;
      background: #38f;
      border: 0;
      padding: 8px 16px;
      font-size: 12px;
      color: #ffffff;
      cursor: pointer;
      white-space: nowrap;
      text-decoration: none;
    }
  }
  &.fixed-header,
  &:hover {
    background: rgba(16, 16, 16, 0.8);
    backdrop-filter: blur(24px);
  }
}

@media (max-width: 980px) {
  .header {
    padding: 0 8px;
    background: rgba(16, 16, 16, 0.8);
    height: 48px;
    .header_logo_wrapper {
      height: 36px;
      img {
        height: 36px;
      }
      .title {
        display: none;
      }
      .menu_icon {
        display: flex !important;
      }
      .menu_navigation {
        padding-top: 48px;
      }
    }
    .navigation {
      display: none;
    }
  }
}
</style>
