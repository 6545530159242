<template>
  <div>
    <HeaderRoot></HeaderRoot>
    <div class="banner_module">
      <div class="swiper">
        <div class="swiper_wrapper">
          <div class="swiper_slide">
            <img :src="images[currentIndex].imageUrl" alt="carousel-image" />
          </div>
        </div>
      </div>
      <!--轮播图导航按钮-->
      <div class="swiper_button_prev" @click="prevImage">
        <img src="../assets/swiper_button_prev.png" />
      </div>
      <div class="swiper_button_next" @click="nextImage">
        <img src="../assets/swiper_button_next.png" />
      </div>
      <!--轮播图分页器-->
      <div class="banner_tools">
        <a
          class="banner_toolItem"
          v-for="(image, index) in images"
          :key="index"
          :class="{active:index === currentIndex}"
          @click="setCurrentIndex(index)"
        >
          <div class="toolItem_title">{{image.title}}</div>
          <div class="toolItem_sub_title">{{image.desc}}</div>
          <div class="toolItem_icon">
            <img :src="image.iconUrl" class="iconUrl" />
            <img :src="image.iconUrl2" class="iconUrl2" />
          </div>
        </a>
      </div>
    </div>
    <div class="module">
      <div class="module_title">7600是什么？</div>
      <div class="module_desc">致力于企业"互动营销控件、私域流量获取"的工具研发。</div>
      <div class="data_wrapper">
        <div class="data_item">
          <div class="item_data">
            <!-- :suffix="'年+'" -->
            <count-to class="number" :start-val="0" :end-val="10" :duration="2000"></count-to>
            <span class="unit">年+</span>
          </div>
          <div class="item_describe">互动营销经验</div>
        </div>
        <div class="data_item">
          <div class="item_data">
            <count-to class="number" :start-val="0" :end-val="500" :duration="2000"></count-to>
            <span class="unit">+</span>
          </div>
          <div class="item_describe">细分营销场景</div>
        </div>
        <div class="data_item">
          <div class="item_data">
            <count-to class="number" :start-val="0" :end-val="1000" :duration="2000"></count-to>
            <span class="unit">+</span>
          </div>
          <div class="item_describe">活动礼品提供</div>
        </div>
        <div class="data_item">
          <div class="item_data">
            <count-to class="number" :start-val="0" :end-val="50000" :duration="2000"></count-to>
            <span class="unit">+</span>
          </div>
          <div class="item_describe">企业信赖之选</div>
        </div>
        <div class="data_item">
          <div class="item_data">
            <count-to class="number" :start-val="0" :end-val="18560000" :duration="2000"></count-to>
            <span class="unit">+</span>
          </div>
          <div class="item_describe">累计活动</div>
        </div>
        <div class="data_item">
          <div class="item_data">
            <count-to class="number" :start-val="0" :end-val="200000000" :duration="2000"></count-to>
            <span class="unit">+</span>
          </div>
          <div class="item_describe">累计为客户带来流量</div>
        </div>
      </div>
    </div>
    <div class="module do_module_bg">
      <div class="module_title">7600能为您做什么？</div>
      <div class="module_desc">提供市场最主流的"营销活动控件"设计开发，并共享于市场，赋能于企业，实现仅需简单操作配置即可使用。</div>
      <div class="do_accordion">
        <div
          class="do_item"
          v-for="(box, index) in boxs"
          :key="index"
          :class="{active:activeIndex === index}"
          @mouseover="setActive(index)"
        >
          <div class="item_content">
            <div class="title">{{box.title}}</div>
            <div class="desc">{{box.desc}}</div>
            <div class="desc_expand">
              <div v-for="(e, f) in box.desc_expand" :key="f" class="desc_expand_li">{{e}}</div>
            </div>
          </div>
          <div class="item_content_expand">
            <video autoplay="autoplay" muted="muted">
              <source src="../assets/H5.mp4" />
            </video>
          </div>
          <div class="item_mask"></div>
        </div>
      </div>
    </div>
    <div class="module">
      <div class="module_title">精美模板，简单操作，轻松使用。</div>
      <div class="hot_templates">
        <div class="templates one">
          <div class="box_detail" v-for="(item, index) in items" :key="index">
            <a :href="mmsurl" target="_blank" class="image_box">
              <div class="tag_member" v-show="item.expense=='会员免费用'">会员免费用</div>
              <div class="tag_free" v-show="item.expense=='免费'">免费</div>
              <div class="type_name">{{item.tag}}</div>
              <img :src="item.imageUrl" />
            </a>
            <div class="card_info">
              <div class="info_title">{{item.title}}</div>
              <div class="info_num">
                <div class="type_name_tip">{{item.tag}}</div>
                <div class="num_eye">
                  <img src="../assets/icon_eye.png" />
                  <span>{{item.num}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="templates two">
          <div class="box_detail" v-for="(item, index) in items" :key="index">
            <a :href="mmsurl" target="_blank" class="image_box">
              <div class="tag_member" v-show="item.expense=='会员免费用'">会员免费用</div>
              <div class="tag_free" v-show="item.expense=='免费'">免费</div>
              <div class="type_name">{{item.tag}}</div>
              <img :src="item.imageUrl" />
            </a>
            <div class="card_info">
              <div class="info_title">{{item.title}}</div>
              <div class="info_num">
                <div class="type_name_tip">{{item.tag}}</div>
                <div class="num_eye">
                  <img src="../assets/icon_eye.png" />
                  <span>{{item.num}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="templates three">
          <div class="box_detail" v-for="(item, index) in items" :key="index">
            <a :href="mmsurl" target="_blank" class="image_box">
              <div class="tag_member" v-show="item.expense=='会员免费用'">会员免费用</div>
              <div class="tag_free" v-show="item.expense=='免费'">免费</div>
              <div class="type_name">{{item.tag}}</div>
              <img :src="item.imageUrl" />
            </a>
            <div class="card_info">
              <div class="info_title">{{item.title}}</div>
              <div class="info_num">
                <div class="type_name_tip">{{item.tag}}</div>
                <div class="num_eye">
                  <img src="../assets/icon_eye.png" />
                  <span>{{item.num}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn_more">
        <button>查看更多</button>
      </div>
    </div>
    <FooterRoot></FooterRoot>
  </div>
</template>

<script>
import HeaderRoot from "./HeaderRoot.vue";
import FooterRoot from "./FooterRoot.vue";
import countTo from "vue-count-to"; //数字增长动画
export default {
  name: "FormCenter",
  props: {},
  components: {
    HeaderRoot,
    FooterRoot,
    countTo //数字增长动画
  },
  data() {
    return {
      mmsurl: process.env.VUE_APP_MMS_BASE_URL,
      images: [
        {
          imageUrl: require("../assets/banner1.jpg"),
          title: "互动模板",
          desc: "无需开发简单配置 互动活动轻松使用",
          iconUrl: require("../assets/toolItem_icon_1.png"),
          iconUrl2: require("../assets/toolItem_icon_1_2.png")
        },
        {
          imageUrl: require("../assets/banner2.jpg"),
          title: "AI数字人",
          desc: "仅需提供文案 轻松生成精美视频",
          iconUrl: require("../assets/toolItem_icon_2.png"),
          iconUrl2: require("../assets/toolItem_icon_2_2.png")
        },
        {
          imageUrl: require("../assets/banner3.jpg"),
          title: "H5模板",
          desc: "上千套模板 总一有套适合您",
          iconUrl: require("../assets/toolItem_icon_3.png"),
          iconUrl2: require("../assets/toolItem_icon_3_2.png")
        },
        {
          imageUrl: require("../assets/banner4.jpg"),
          title: "推广获客",
          desc: "集成主流获客方法 让工具找客户更高效",
          iconUrl: require("../assets/toolItem_icon_4.png"),
          iconUrl2: require("../assets/toolItem_icon_4_2.png")
        }
      ],
      currentIndex: 0,
      intervalId: null,
      items: [
        {
          imageUrl: require("../assets/template-1.jpg"),
          title: "暴雨出行注意安全介绍电子画册",
          tag: "H5",
          expense: "会员免费用",
          num: "476"
        },
        {
          imageUrl: require("../assets/template-2.jpg"),
          title: "简约风大学新生报到指南入学指南",
          tag: "海报",
          expense: "免费",
          num: "246"
        },
        {
          imageUrl: require("../assets/template-3.jpg"),
          title: "简约金牌讲师人物介绍宣传全屏海报",
          tag: "互动",
          expense: "会员免费用",
          num: "57"
        },
        {
          imageUrl: require("../assets/template-4.jpg"),
          title: "免费版红金企业年会年终客户答谢会颁奖典礼邀请函",
          tag: "互动",
          expense: "免费",
          num: "107"
        },
        {
          imageUrl: require("../assets/template-5.jpg"),
          title: "简约品质美妆专享直播背景",
          tag: "互动",
          expense: "会员免费用",
          num: "144"
        },
        {
          imageUrl: require("../assets/template-6.jpg"),
          title: "港澳亲子游报团旅游促销活动宣传简约风",
          tag: "互动",
          expense: "会员免费用",
          num: "1657"
        },
        {
          imageUrl: require("../assets/template-7.jpg"),
          title: "中式中秋节促销活动美容美妆医美整形中秋答谢活动",
          tag: "互动",
          expense: "会员免费用",
          num: "76"
        },
        {
          imageUrl: require("../assets/template-8.jpg"),
          title: "清新开学季课外班报名抽奖活动",
          tag: "互动",
          expense: "免费",
          num: "24"
        },
        {
          imageUrl: require("../assets/template-9.jpg"),
          title: "复古中国风中秋节金秋大闸蟹活动促销",
          tag: "互动",
          expense: "会员免费用",
          num: "899"
        },
        {
          imageUrl: require("../assets/template-10.jpg"),
          title: "卡通创意开学季学习文具店铺促销学习用品优惠活动",
          tag: "H5",
          expense: "免费",
          num: "297"
        },
        {
          imageUrl: require("../assets/template-11.jpg"),
          title: "暴雨出行注意安全介绍电子画册",
          tag: "H5",
          expense: "会员免费用",
          num: "476"
        },
        {
          imageUrl: require("../assets/template-12.jpg"),
          title: "简约风大学新生报到指南入学指南",
          tag: "海报",
          expense: "免费",
          num: "246"
        },
        {
          imageUrl: require("../assets/template-13.jpg"),
          title: "简约金牌讲师人物介绍宣传全屏海报",
          tag: "互动",
          expense: "会员免费用",
          num: "57"
        },
        {
          imageUrl: require("../assets/template-14.jpg"),
          title: "免费版红金企业年会年终客户答谢会颁奖典礼邀请函",
          tag: "互动",
          expense: "免费",
          num: "107"
        },
        {
          imageUrl: require("../assets/template-15.jpg"),
          title: "简约品质美妆专享直播背景",
          tag: "互动",
          expense: "会员免费用",
          num: "144"
        },
        {
          imageUrl: require("../assets/template-16.jpg"),
          title: "港澳亲子游报团旅游促销活动宣传简约风",
          tag: "互动",
          expense: "会员免费用",
          num: "1657"
        },
        {
          imageUrl: require("../assets/template-17.jpg"),
          title: "中式中秋节促销活动美容美妆医美整形中秋答谢活动",
          tag: "互动",
          expense: "会员免费用",
          num: "76"
        },
        {
          imageUrl: require("../assets/template-18.jpg"),
          title: "清新开学季课外班报名抽奖活动",
          tag: "互动",
          expense: "免费",
          num: "24"
        },
        {
          imageUrl: require("../assets/template-19.jpg"),
          title: "复古中国风中秋节金秋大闸蟹活动促销",
          tag: "互动",
          expense: "会员免费用",
          num: "899"
        },
        {
          imageUrl: require("../assets/template-20.jpg"),
          title: "卡通创意开学季学习文具店铺促销学习用品优惠活动",
          tag: "H5",
          expense: "免费",
          num: "297"
        },
        {
          imageUrl: require("../assets/template-21.jpg"),
          title: "暴雨出行注意安全介绍电子画册",
          tag: "H5",
          expense: "会员免费用",
          num: "476"
        },
        {
          imageUrl: require("../assets/template-22.jpg"),
          title: "简约风大学新生报到指南入学指南",
          tag: "海报",
          expense: "免费",
          num: "246"
        },
        {
          imageUrl: require("../assets/template-23.jpg"),
          title: "简约金牌讲师人物介绍宣传全屏海报",
          tag: "互动",
          expense: "会员免费用",
          num: "57"
        },
        {
          imageUrl: require("../assets/template-24.jpg"),
          title: "免费版红金企业年会年终客户答谢会颁奖典礼邀请函",
          tag: "互动",
          expense: "免费",
          num: "107"
        },
        {
          imageUrl: require("../assets/template-25.jpg"),
          title: "简约品质美妆专享直播背景",
          tag: "互动",
          expense: "会员免费用",
          num: "144"
        },
        {
          imageUrl: require("../assets/template-26.jpg"),
          title: "港澳亲子游报团旅游促销活动宣传简约风",
          tag: "互动",
          expense: "会员免费用",
          num: "1657"
        }
      ],
      activeIndex: 0, //默认第一个元素
      boxs: [
        {
          title: "线上营销互动活动模板",
          desc: "集成'活动基本配置、活动礼品提供、分享推广获客'等功能于一体。",
          desc_expand: [
            "海量模板",
            "实时数据",
            "线上线下获客",
            "销售转化提升",
            "品牌传播"
          ]
        },
        {
          title: "AI数字人宣传视频",
          desc: "提供多场景数字人小视频模板,可修改文案、语音、数字人等功能。",
          desc_expand: ["易操作", "高品质", "定制化", "批量生成"]
        },
        {
          title: "H5精美模板",
          desc:
            "提供上千套模板,可修改其中图片、文字、人物为自己需要的,是移动端建站和活动宣传的好帮手。",
          desc_expand: ["海量模板", "简单交互", "零代码3分钟制作"]
        },
        {
          title: "推广获客",
          desc:
            "探索当下最主流的私域流量获客方式并研发成工具,让工具代替人工实现流量获取、激活、转化等。",
          desc_expand: [
            "自动化",
            "实时化",
            "智能化",
            "用户有效互动",
            "一站式营销管理"
          ]
        }
      ]
    };
  },
  methods: {
    //轮播图导航按钮
    nextImage() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    prevImage() {
      this.currentIndex =
        (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
    setCurrentIndex(index) {
      this.currentIndex = index;
    },
    startAutoPlay() {
      this.intervalId = setInterval(this.nextImage, 5000); // 每3秒自动切换到下一张图片
    },
    stopAutoPlay() {
      clearInterval(this.intervalId);
    },
    //伸缩
    setActive(index) {
      this.activeIndex = index; //更新activeIndex来动态改变active类
    }
  },
  mounted() {
    //轮播图自动播放
    this.startAutoPlay();
  },
  beforeDestroy() {
    this.stopAutoPlay();
  }
};
</script>

<style lang="less" scoped>
.banner_module {
  position: relative;
  height: 660px;
  .swiper {
    .swiper_wrapper {
      .swiper_slide {
        width: 100%; /* 容器宽度，‌可以根据需要设置 */
        height: 660px; /* 容器固定高度 */
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        overflow: hidden; /* 隐藏超出容器的部分 */
        img {
          max-height: 100%; /* 图片最大高度不超过容器 */
          width: auto; /* 图片宽度等比例缩放 */
          position: relative;
        }
      }
    }
  }
  .swiper_button_prev,
  .swiper_button_next {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 3;
    top: 298px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .swiper_button_prev {
    left: 72px;
    right: auto;
  }
  .swiper_button_next {
    left: auto;
    right: 72px;
  }
  .banner_tools {
    color: #ffffff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
    z-index: 1;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .banner_toolItem {
      width: 282px;
      margin: 0 12px;
      border-radius: 16px;
      padding: 24px;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      flex-direction: column;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      &.active {
        background: #ffffff;
        .toolItem_title {
          color: #1a1a1a;
        }
        .toolItem_sub_title {
          color: rgba(0, 0, 0, 0.25);
        }
        .iconUrl {
          display: none;
        }
        .iconUrl2 {
          display: block;
        }
      }
      .toolItem_title {
        font-size: 22px;
        font-weight: 600;
        line-height: 30px;
        color: #ffffff;
      }
      .toolItem_sub_title {
        line-height: 20px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);
        overflow: hidden;
        text-align: center;
        flex-grow: 1;
      }
      .iconUrl {
        display: block;
      }
      .iconUrl2 {
        display: none;
      }
    }
  }
}
.module {
  margin: 0 auto;
  text-align: center;
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.do_module_bg {
    background: url("../assets/home-background-three.jpg");
    // background: linear-gradient(94.89deg, rgb(0, 8, 25) 0%, rgb(21, 40, 88) 99.96%);
  }
  .module_title {
    line-height: 48px;
    font-size: 36px;
    color: #1a1a1a;
    padding: 0 40px;
  }
  .module_desc {
    margin-top: 16px;
    margin-bottom: 56px;
    font-size: 16px;
    line-height: 28px;
    color: #595959;
    padding: 0 40px;
  }
  .data_wrapper {
    display: flex;
    flex-wrap: wrap;
    .data_item {
      width: 33.3%;
      .item_data {
        height: 60px;
        .number {
          font-size: 44px;
          line-height: 60px;
          font-weight: 600;
          letter-spacing: 0;
        }
        .unit {
          font-size: 18px;
        }
      }
      .item_describe {
        font-size: 14px;
        line-height: 20px;
        color: #595959;
        margin-top: 8px;
      }
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-bottom: 56px;
      }
    }
  }
  .do_accordion {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    .do_item {
      // padding: 16px;
      position: relative;
      width: 204px;
      height: 388px;
      background: linear-gradient(
        165.45deg,
        hsla(0, 0%, 100%, 0.8) 12.78%,
        hsla(0, 0%, 100%, 0) 129.73%
      );
      border: 1px solid #fff;
      filter: drop-shadow(0 8.0787px 9.08853px rgba(47, 122, 235, 0.12));
      border-radius: 8px;
      transition: width 1s;
      // transition-delay: 0.5s;
      .item_content_expand {
        width: 0;
        transition: width 1s;
        // transition-delay: 1s;
        overflow: hidden;
        video {
          width: 0;
          height: 0;
          transition: width 1s;
          // transition-delay: 1s;
        }
      }
      &.active {
        width: 552px;
        cursor: pointer;
        .item_content_expand {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(1, 107, 205, 0.9);
          border-radius: 8px;
          display: flex;
          justify-content: end;
          align-items: center;
          video {
            // width: 218px;
            // height: 388px;
            // float: right;
            width: 65%;
            height: auto;
            object-fit: cover;
          }
        }
        .item_mask {
          display: none;
        }
        .item_content {
          width: 302px;
          justify-content: flex-start;
          text-align: left;
          .desc {
            display: none;
          }
          .desc_expand {
            display: flex;
            flex-direction: column;
            margin-top: 4px;
            .desc_expand_li {
              color: #ffffff;
              font-size: 14px;
              line-height: 28px;
              position: relative;
              padding-left: 18px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              &::before {
                background: url(../assets/do_item_icon.png) no-repeat 50%;
                background-size: 80%;
                content: "";
                width: 13px;
                height: 10px;
                left: 0;
                position: absolute;
                top: 8px;
              }
            }
          }
        }
      }
      &:first-child {
        background-image: url("../assets/do_accordion_1.png");
        background-position-x: 55%;
        background-position-y: 50%;
      }
      &:nth-child(2) {
        background-image: url("../assets/do_accordion_2.png");
        background-position: center 55%;
        background-position-y: 50%;
      }
      &:nth-child(3) {
        background-image: url("../assets/do_accordion_3.png");
        background-position-x: 89%;
        background-position-y: 50%;
      }
      &:nth-child(4) {
        background-image: url("../assets/do_accordion_4.png");
        background-position-x: 50%;
        background-position-y: 50%;
      }
      .item_mask {
        background: linear-gradient(
          180deg,
          hsla(0, 0%, 9%, 0),
          hsla(0, 0%, 9%, 0.99) 80%
        );
        position: absolute;
        bottom: 0;
        left: 0;
        height: 240px;
        width: 100%;
        border-radius: 0 0 8px 8px;
        z-index: 9;
      }
      .item_content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 98%;
        color: #ffffff;
        position: relative;
        z-index: 10;
        padding: 16px;
        transition: justify-content 2s;
        transition-delay: 8s;
        .title {
          font-size: 18px;
          line-height: 28px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .desc {
          margin-top: 8px;
          font-size: 14px;
          line-height: 24px;
          height: 104px;
          opacity: 0.9;
          margin-top: 4px;
        }

        .desc_expand {
          display: none;
        }
      }
    }
  }
  .hot_templates {
    width: 100%;
    overflow: hidden;
    .templates {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      width: 100%;
      transition: transform 0.5s linear; //平滑过渡效果

      &.one {
        margin-top: 40px;
        animation: 45s move-it ease alternate infinite 5s;
        transform: translateX(2%);
      }
      &.three {
        margin-bottom: 40px;
        animation: 50s move-it ease alternate infinite 6s;
        transform: translateX(2%);
      }
      &.two {
        animation: 55s move-it-2 ease alternate infinite 5s;
        transform: translateX(-50%);
      }
      &:hover {
        animation-play-state: paused;
      }
      .box_detail {
        width: 108px;
        border-radius: 6px;
        background: #ffffff;
        margin-right: 16px;
        position: relative;
        border: 1px solid hsla(0, 0%, 39.2%, 0.1);
        .image_box {
          display: inline-flex;
          z-index: 1;
          position: relative;
          max-height: 230px;
          border-radius: 6px;
          img {
            width: 108px;
            border-radius: 6px;
            display: block;
          }
          .tag_member {
            display: none;
            position: absolute;
            right: 4px;
            bottom: 4px;
            z-index: 3;
            line-height: 20px;
            padding: 0 4px;
            background: linear-gradient(138deg, #ffeed4, #ffd59f);
            border-radius: 4px;
            font-size: 12px;
            color: #6c300a;
            font-weight: 600;
          }
          .tag_free {
            display: none;
            position: absolute;
            right: 4px;
            bottom: 4px;
            z-index: 3;
            min-width: 36px;
            line-height: 20px;
            border-radius: 4px;
            font-size: 12px;
            color: #ffead8;
            padding: 0 4px;
            background: linear-gradient(354deg, #3b3b52, #75758b);
            font-weight: 600;
          }
          .type_name {
            position: absolute;
            right: 2px;
            bottom: 2px;
            z-index: 3;
            min-width: 36px;
            line-height: 18px;
            border-radius: 3px;
            font-size: 10px;
            color: #ffffff;
            padding: 0 4px;
            background: rgba(0, 0, 0, 0.6);
          }
        }
        .card_info {
          display: none;
          position: absolute;
          border-radius: 0 0 6px 6px;
          height: 36px;
          width: 100%;
          background: #ffffff;
          padding: 0 4px;
          bottom: -36px;
          z-index: 4;
          .info_title {
            font-size: 12px;
            line-height: 16px;
            text-align: left;
            color: #1a1a1a;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .info_num {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 10px;
            color: #595959;
            line-height: 16px;
            .num_eye {
              display: flex;
              align-items: center;
            }
          }
        }
        &:hover {
          z-index: 9999;
          top: -24px;
          box-shadow: 0 6px 14px 0 rgb(0 0 0 / 8%),
            0 3px 6px -4px rgb(0 0 0 / 12%);
          border: 0;
          border-radius: 6px 6px 0 0;
          .image_box {
            border-radius: 6px 6px 0 0;
            img {
              border-radius: 6px 6px 0 0;
            }
          }
          .type_name {
            display: none;
          }
          .tag_member,
          .tag_free,
          .card_info {
            display: block;
          }
          .card_info {
            box-shadow: 0 6px 14px 0 rgb(0 0 0 / 8%),
              0 3px 6px -4px rgb(0 0 0 / 12%);
          }
        }
      }
    }
  }
  .btn_more {
    display: flex;
    justify-content: center;
    button {
      width: 216px;
      height: 44px;
      color: #1a1a1a;
      font-size: 16px;
      background-color: #f7f9fa;
      border-radius: 22px;
      border: 0;
      &:hover {
        background-color: #3388ff;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }

  @keyframes move-it {
    0%,
    90%,
    100% {
      transform: translateX(2%);
    }
    45% {
      transform: translateX(-50%);
    }
  }
  @keyframes move-it-2 {
    0%,
    90%,
    100% {
      transform: translateX(-50%);
    }
    45% {
      transform: translateX(5%);
    }
  }
}
/* 屏幕宽度小于1280px时，容器宽度自适应屏幕 */
@media (max-width: 1280px) {
  .banner_module {
    height: 560px;
    overflow: hidden;
    .swiper {
      .swiper_wrapper {
        .swiper_slide {
          height: 560px;
          img {
            display: inline-block;
            /* 以下为图片能居中的主要代码，需父级user-img相对定位 position:relative; */
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
          }
        }
      }
    }
    .swiper_button_prev,
    .swiper_button_next {
      display: none;
    }
    .banner_tools {
      .banner_toolItem {
        width: 210px;
        margin: 0 8px;
      }
    }
  }
  .module {
    .data_wrapper {
      .data_item {
        .item_data {
          .number {
            font-size: 40px;
          }
        }
      }
    }
    .do_accordion {
      width: 100%;
      padding: 0 20px;
      .do_item {
        width: 20%;
        height: 320px;
        margin: 0 4px;
        &.active {
          width: 40%;
        }

        .item_mask {
          height: 200px;
        }
        .item_content {
          .title {
            font-size: 18px;
            line-height: 24px;
          }
          .desc {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
@media (max-width: 980px) {
  .banner_module {
    height: 480px;
    .swiper {
      .swiper_wrapper {
        .swiper_slide {
          height: 480px;
        }
      }
    }
    .banner_tools {
      .banner_toolItem {
        width: 174px;
        .toolItem_title {
          font-size: 18px;
        }
        .toolItem_sub_title {
          display: none;
        }
      }
    }
  }
  .module {
    .data_wrapper {
      .data_item {
        .item_data {
          .number {
            font-size: 36px;
          }
        }
      }
    }
    .do_accordion {
      .do_item {
        .item_content {
          .desc {
            overflow: hidden;
          }
        }
        &.active {
          .item_content {
            width: 200px !important;
            .title {
              padding-left: 8px;
              background: rgba(#3b3b52, 0.6);
              border-radius: 3px;
              padding-top: 4px;
              padding-bottom: 4px;
            }
            .desc_expand {
              padding-left: 8px;
              background: rgba(#3b3b52, 0.6);
              border-radius: 3px;
              .desc_expand_li {
              }
            }
          }
          .item_content_expand {
            video {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

/* 屏幕宽度小于768px时，容器宽度自适应屏幕 */
@media (max-width: 768px) {
  .banner_module {
    height: 320px;
    margin-top: 48px;
    .swiper {
      .swiper_wrapper {
        .swiper_slide {
          height: 320px;
        }
      }
    }
    .banner_tools {
      bottom: 24px;
      width: 100%;
      .banner_toolItem {
        padding: 8px;
        width: 25%;
        .toolItem_title {
          font-size: 16px;
          white-space: nowrap;
        }
        img {
          width: 56px;
        }
      }
    }
  }
  .module {
    padding-top: 48px;
    padding-bottom: 48px;
    .module_title {
      font-size: 30px;
      line-height: 38px;
    }
    .module_desc {
      margin-top: 8px;
      margin-bottom: 24px;
      font-size: 12px;
      line-height: 20px;
    }
    .data_wrapper {
      .data_item {
        .item_data {
          height: 32px;
          .number {
            font-size: 24px;
            letter-spacing: -1px;
          }
        }
        .item_describe {
          margin-top: 16px;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          margin-bottom: 16px;
        }
      }
    }
    .do_accordion {
      .do_item {
        margin: 0;
        .item_content {
          width: auto !important;
          .desc {
            overflow: hidden;
            height: 240px !important;
          }
        }
        &.active {
          .item_content {
            width: auto !important;
          }
          .item_content_expand {
            video {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}

/* 屏幕宽度小于480px时，容器宽度自适应屏幕 */
@media (max-width: 480px) {
  .banner_module {
    height: 220px;
    .swiper {
      .swiper_wrapper {
        .swiper_slide {
          height: 220px;
        }
      }
    }
    .banner_tools {
      bottom: 12px;
      .banner_toolItem {
        border-radius: 8px;
        margin: 0 6px;
        width: 25%;
        .toolItem_title {
          font-size: 14px;
          white-space: nowrap;
        }
        img {
          width: 40px;
          margin-top: -8px;
        }
      }
    }
  }

  .module {
    .module_title {
      font-size: 24px;
      line-height: 32px;
    }
    .data_wrapper {
      .data_item {
        .item_data {
          height: 24px;
          .number {
            font-size: 16px;
          }
        }
        .item_describe {
          margin-top: 20px;
          font-size: 12px;
        }
      }
    }
    .do_accordion {
      .do_item {
        &.active {
          .item_content_expand {
            video {
              width: auto;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
